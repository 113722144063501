<template>
  <ul class="dropdown">
    <li :class="{ 'dropdown-label--tooltip': toolText }" class="dropdown-label">
      <span v-html="label"></span>
      <div v-if="toolText" @click="tooltip(toolText)" class="dropdown-tooltip">
        <img src="../assets/info.png" alt />
      </div>
    </li>
    <li
      v-if="options.length != 0"
      @click="toggleListVisibility"
      id="dropdown-selected"
      tabindex="0"
    >{{ initialValue }}</li>
    <div class="dropdown-arrow">
      <svg width="15" height="10" viewBox="0 0 15 10" fill-rule="evenodd">
        <title>Open drop down</title>
        <path d="M10 0L5 5 0 0z" />
      </svg>
    </div>
    <li aria-expanded="false" role="list" class="dropdown-listContainer">
      <ul v-if="options.length != 0" class="dropdown-list">
        <li
          v-for="(option, i) in options"
          :key="i"
          @click="selectItem($event, option)"
          class="dropdown-listItem"
        >{{ option }}</li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: "generalDropdown",
  props: ["reset", "activeState", "options", "label", "toolText"],
  data() {
    return {
      tooltipText: "",
      tooltipActive: false,
      results: [],
      initialValue: ""
    };
  },
  methods: {
    setSelectedListItem(e, option) {
      this.initialValue = option
    },
    closeList() {
      let list = this.$el.querySelector(".dropdown-list");
      let listContainer = this.$el.querySelector(".dropdown-listContainer");
      let dropdownArrow = this.$el.querySelector(".dropdown-arrow");
      if (list) {
        list.classList.remove("is-open");
        dropdownArrow.classList.remove("is-open");
        listContainer.setAttribute("aria-expanded", false);
      }
    },
    toggleListVisibility() {
      let list = this.$el.querySelector(".dropdown-list");
      let listContainer = this.$el.querySelector(".dropdown-listContainer");
      let dropdownArrow = this.$el.querySelector(".dropdown-arrow");
      list.classList.toggle("is-open");
      dropdownArrow.classList.toggle("is-open");
      listContainer.setAttribute(
        "aria-expanded",
        list.classList.contains("is-open")
      );
    },
    tooltip(text) {
      this.$emit("tooltip", text);
      this.tooltipActive = true;
      this.tooltipText = text;
    },
    selectItem(event, option) {
      this.setSelectedListItem(event, option);
      this.closeList();

      switch (this.label) {
        case "Bedrooms":
          this.$emit("selectedBedrooms", this.getBedroomsValue(option));
          break;
        case "LEED Home?":
          if (option == "No") {
            option = false;
          } else if (option == "Yes") {
            option = true;
          }
          this.$emit("selectedLeed", option);
          break;
        case "Floor Area":
          this.$emit("selectedFloorArea", this.getFloorAreaMax(option));
          break;
        case "ENERGY STAR Home?":
          if (option == "No") {
            option = false;
          } else if (option == "Yes") {
            option = true;
          }
          this.$emit("selectedEnergyStar", option);
          break;
        case "System Type":
          this.$emit("selectedSystemType", option);
          break;
        case "WHMV Design Flow Rate (cfm)":
          this.$emit("selectedFlowRate", option);
          break;
        case "On-site testing of WHMV flow rate?":
          if (option == "No") {
            option = false;
          } else if (option == "Yes") {
            option = true;
          }
          this.$emit("selectedOnSiteTest", option);
          break;
        case "Require HEPA Filtration?":
          if (option == "No") {
            option = false;
          } else if (option == "Yes") {
            option = true;
          }
          this.$emit("selectedRequireHEPA", option);
          break;
        case "Account for Infiltration?":
          if (option == "Do not account for Infiltration") {
            option = 0;
          }
          if (option == "Use Default Infiltration") {
            option = 1;
          }
          if (option == "Use Calculated Infiltration") {
            option = 2;
          }
          this.$emit("selectedInfiltration", option);
          break;
        case "Height Above Grade (ft)":
          option = parseInt(option);
          this.$emit("selectedHeightAboveGrade", option);
          break;
        case "Conditioned Volume (ft^3)":
          option = parseInt(option);
          this.$emit("selectedConditionedVolume", option);
          break;
        case "Detached Envelope Area Ratio":
          option = parseInt(option);
          this.$emit("selectedDetachedEnvelopeArea", option);
          break;
        case "Leakage (cfm50)":
          option = parseInt(option);
          this.$emit("selectedLeakage", option);
          break;
        default:
          break;
      }
    },
    getBedroomsValue(str) {
      switch (str) {
        case "0-1":
          return 1;
        case "2-3":
          return 3;
        case "4-5":
          return 5;
        case "6-7":
          return 7;
        case ">7":
          return 99;
        case "1":
          return 1;
        case "2":
          return 2;
        case "3":
          return 3;
        case "4":
          return 4;
        case "5":
          return 5;
        case "6":
          return 6;
        case "7":
          return 7;
        case "8":
          return 8;
        case "9":
          return 9;
        case "10":
          return 10;
        default:
          break;
      }
    },
    getFloorAreaMax(str) {
      switch (str) {
        case "<1,500":
          return 1500;
        case "1,501-3,000":
          return 3000;
        case "3,001-4,500":
          return 4500;
        case "4,501-6,000":
          return 6000;
        case "6,001-7,500":
          return 7500;
        case ">7,500":
          return 9000;
        default:
          break;
      }
    },
    setInitialValue() {
      this.initialValue = this.options[0];
    }
  },
  mounted() {
    this.setInitialValue();
    document.addEventListener("click", e => {
      if (!this.$el.contains(e.target)) {
        this.closeList();
      }
    });

    document.addEventListener("touchstart", e => {
      if (!this.$el.contains(e.target)) {
        this.closeList();
      }
    });
  },
  watch: {
    reset() {
      this.setInitialValue();
		},
		options() {
      this.setInitialValue();
		}
  }
};
</script>
<style lang="scss">
// GLOBAL DROPDOWN STYLING
.dropdown {
  list-style: none;
  position: relative;
  border: 1px solid #a2a4b2;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 100%;
  height: 55px;
  cursor: pointer;
  padding: 0;
  border-radius: 5px;
}
.dropdown-label {
  position: absolute;
  top: -13px;
  left: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #fff;
  padding: 0 10px 0 10px;
  font-weight: 600;
  font-size: 1.25em;
  font-family: Lato, sans-serif;
  color: #002855;

  @media (max-width: 870px) {
    font-size: 1rem;
  }
}
.dropdown-label--tooltip {
  padding: 0 40px 0 10px;
}
.dropdown-arrow {
  position: absolute;
  right: 10px;
  top: 45%;
  transition: transform 0.2s linear;
  z-index: -1;
}
.dropdown-arrow.is-open {
  transform: rotate(-180deg);
}
.dropdown-list {
  width: 100%;
  position: absolute;
  left: 0;
  border: 1px solid #e0e0e0;
  border-top: none;
  box-shadow: 0 15px 35px 0 rgba(42, 51, 83, 0.12),
    0 5px 15px rgba(0, 0, 0, 0.06);
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  z-index: 100;
  background-color: #fff;
  list-style: none;
  margin: 0;
  padding: 0;

  &.is-open {
    opacity: 1;
    overflow: auto;
    max-height: 55vh;
		z-index: 5;
    @media (max-width: 550px) {
      max-height: 100vh;
    }
  }
}
.dropdown-listContainer {
  position: relative;
}
.dropdown-listItem {
  font-family: Lato, sans-serif;
  color: #444444;
  padding: 10px 0px 10px 15px;
  color: #444444;
  list-style-position: inside;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 550px) {
    font-size: 12px;
  }
}
.dropdown-listItem:hover,
.dropdown-listItem:focus {
  background-color: #002855;
  color: white;
}

#dropdown-selected {
  font-family: Lato, sans-serif;
  color: #444444;
  padding: 20px 0px 14px 0;
  padding-left: 25px;
  list-style-position: inside;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
#dropdown-selected:focus {
  outline: 1px solid #002855;
}

.dropdown-tooltip {
  width: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-tooltip img {
  max-width: 100%;
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
