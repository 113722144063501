<template>
    <div class="specifierForm">
        <div class="specifierForm-logo print">
            <img src="../assets/logo.png" alt="logo" />
        </div>
        <h1 class="specifierForm-heading print">
            Whole House Mechanical Ventilation (WHMV) Specifier
        </h1>
        <h2 class="specifierForm-subHeading print">
            Code compliant solutions for one and two family dwellings units and
            townhouses up to three stories
        </h2>
        <form class="specifierForm-form" @submit.prevent="onSubmit">
            <h3 v-if="!stateSelected" class="specifierForm-instruction">
                Let’s get started by selecting the state where the dwelling unit will be
                located
            </h3>

            <div class="formBlocks">
                <div class="formBlock">
                    <state-dropdown @selectedState="selectedState"
                                    @tooltip="tooltip"
                                    :label="stateLabel"
                                    :options="states"
                                    :toolText="
							'Select the state where the dwelling unit will be located.'
						"></state-dropdown>
                </div>
                <transition name="fade">
                    <div v-if="stateSelected" class="formBlock">
                        <general-dropdown @selectedBedrooms="selectedBedrooms"
                                          :reset="reset"
                                          :label="bedroomsLabel"
                                          :options="bedrooms"
                                          :key="activeState"></general-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="stateSelected" class="formBlock">
                        <county-dropdown @selectedCounty="selectedCounty"
                                         @tooltip="tooltip"
                                         :label="countyLabel"
                                         :options="counties"
                                         :key="activeState"
                                         :toolText="
								'If your county is not available, select the closest county.'
							"></county-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="stateSelected" class="formBlock">
                        <general-dropdown @selectedLeed="selectedLeed"
                                          :reset="reset"
                                          @tooltip="tooltip"
                                          :label="leedLabel"
                                          :options="leed"
                                          :toolText="
								'Select &quot;Yes&quot; if this home will be built to the specifications of LEED v4 for Homes.'
							"></general-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="stateSelected" class="formBlock">
                        <general-dropdown @selectedFloorArea="selectedFloorArea"
                                          :reset="reset"
                                          @tooltip="tooltip"
                                          :label="floorAreaLabel"
                                          :options="floorArea"
                                          :toolText="
								'Include all thermally conditioned floor area inside the dwelling unit\'s pressure boundary.'
							"></general-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="stateSelected" class="formBlock">
                        <general-dropdown @selectedEnergyStar="selectedEnergyStar"
                                          :reset="reset"
                                          @tooltip="tooltip"
                                          :label="energyStarLabel"
                                          :options="energy"
                                          :toolText="
								'Select &quot;Yes&quot; if this home will be built to the specifications of ENERGY STAR for Homes.'
							"></general-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="showOnSiteTesting" class="formBlock">
                        <general-dropdown @selectedOnSiteTest="selectedOnSiteTest"
                                          :reset="reset"
                                          @tooltip="tooltip"
                                          :label="onSiteTestCompletedLabel"
                                          :options="energy"
                                          :toolText="
								'According to VT RBES, there are two options for sizing the fan, one through prescriptive sizing at higher flow rates and one through on-site testing and confirmation of lower flow rates. To avoid extremely high requirements for ventilation, this tool assumes that in all VT homes over 3,000 sqft, the installer will perform on-site testing of flow rates. Note also that in VT, if there are more than three bedrooms and the WHMV system is not centrally ducted, then prescriptive sizing requires that two or more fans be used.'
							"></general-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="showInfiltration" class="formBlock">
                        <general-dropdown @selectedInfiltration="selectedInfiltration"
                                          :reset="reset"
                                          @tooltip="tooltip"
                                          :label="infiltrationLabel"
                                          :options="infiltration"
                                          :toolText="
								'California provides three options for addressing dwelling unit infiltration when calculating the required WHMV flow rate.'
							"></general-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="showInfiltration && infiltrationDisplay.height" class="formBlock">
                        <general-dropdown @selectedHeightAboveGrade="selectedHeightAboveGrade"
                                          :reset="reset"
                                          @tooltip="tooltip"
                                          :label="heightAboveGradeLabel"
                                          :options="height"
                                          :toolText="
								'Vertical distance between the lowest and highest above-grade points within the pressure boundary (ft).'
							"></general-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="showInfiltration && infiltrationDisplay.conditioned" class="formBlock">
                        <general-text @selectedConditionedVolume="selectedConditionedVolume"
                                      @tooltip="tooltip"
                                      :label="conditionedVolumeLabel"
                                      :options="conditioned"
                                      :toolText="
                                      'Volume of the dwelling unit within conditioned space (ft<sup>3</sup>).'
                                      "></general-text>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="showInfiltration && infiltrationDisplay.detached" class="formBlock">
                        <general-dropdown @selectedDetachedEnvelopeArea="selectedDetachedEnvelopeArea"
                                          :reset="reset"
                                          @tooltip="tooltip"
                                          :label="detachedEnvelopeAreaLabel"
                                          :options="detached"
                                          :toolText="
								'Use 1 for detached dwelling units and a value less than one for townhomes. For townhomes, this is the ratio of exterior envelope surface area that is not attached to garages or other dwelling units to total envelope surface area.'
							"></general-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="showInfiltration && infiltrationDisplay.leakage" class="formBlock">
                        <general-text @selectedLeakage="selectedLeakage"
                                      @tooltip="tooltip"
                                      :label="leakageLabel"
                                      :options="leakage"
                                      :toolText="
								'Dwelling unit leakage rate, measured at 50 Pascals using a blower door test (cfm).'
							"></general-text>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="stateSelected" class="formBlock formBlock--highlight">
                        <general-dropdown @selectedFlowRate="selectedFlowRate"
                                          :reset="resetFlow"
                                          @tooltip="tooltip"
                                          :label="flowRateLabel"
                                          :options="flowRate"
                                          :activeState="activeState"
                                          :toolText="
								`This returns the minimum recommended, average WHMV flow rate. In all states but Florida and Minnesota, you may increase the WHMV Design Flow Rate at your discretion. Higher design flow rates may improve air quality and in some cases increase the available product options (e.g., try selecting 70-80 cfm if there are too few options). Note that higher design flow rates generally result in greater energy use.`
							"></general-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <h3 v-if="stateSelected" class="specifierForm-instruction">
                        User Preference Inputs - Optional
                    </h3>
                </transition>
                <transition name="fade">
                    <div v-if="stateSelected"
                         :class="{ 'formBlock--full': !showHEPA }"
                         class="formBlock">
                        <general-dropdown @selectedSystemType="selectedSystemType"
                                          :reset="reset"
                                          @tooltip="tooltip"
                                          :label="systemTypeLabel"
                                          :options="systemType"
                                          :key="updateSystemType"
                                          :toolText="
                                          'According to VT RBES, if there are > 3 bedrooms in the home, then ventilation systems must be centrally ducted or have 2 or more fans. If you have selected > 3 bedrooms, then the tool limits the system type to those that are compliant with the code.'
                                          "></general-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="stateSelected && showHEPA" class="formBlock">
                        <general-dropdown @selectedRequireHEPA="selectedRequireHEPA"
                                          :reset="reset"
                                          :label="requireHEPALabel"
                                          :options="requireHEPA"></general-dropdown>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="stateSelected" class="formBlock formBlock--rangeGrid">
                        <div class="range-grid">
                            <div class="range-headings">
                                <span class="range-heading">Importance</span>
                                <div class="range-heading">
                                    <span>Doesn't Matter</span>
                                    <span>Significant</span>
                                </div>
                            </div>
                            <div class="range-slider">
                                <label for="" class="range-sliderLabel">First Cost</label>
                                <div class="range-sliderContainer">
                                    <input class="range-sliderInput"
                                           type="range"
                                           min="0"
                                           value="5"
                                           max="10"
                                           step="1"
                                           @input="applyFill($event)"
                                           @change="selectedFirstCost($event)" />
                                </div>
                            </div>
                            <div class="range-slider">
                                <label for="" class="range-sliderLabel">Energy Cost</label>
                                <div class="range-sliderContainer">
                                    <input class="range-sliderInput"
                                           type="range"
                                           min="0"
                                           value="5"
                                           max="10"
                                           step="1"
                                           @input="applyFill($event)"
                                           @change="selectedEnergyCost($event)" />
                                </div>
                            </div>
                            <div class="range-slider">
                                <label for="" class="range-sliderLabel">Noise Level (Sones)</label>
                                <div class="range-sliderContainer">
                                    <input class="range-sliderInput"
                                           type="range"
                                           min="0"
                                           value="5"
                                           max="10"
                                           step="1"
                                           @input="applyFill($event)"
                                           @change="selectedSones($event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="stateSelected" class="specifierForm-submit">
                        <input class="specifierForm-submitBtn"
                               type="submit"
                               value="Submit" />
                    </div>
                </transition>
            </div>
        </form>

        <div v-if="!submitted && stateSelected" class="results-empty">
            <h2 class="results-heading">Solutions</h2>
            <h3 class="results-categoryHeading">Applicable Code</h3>
            <h3 class="results-categoryHeading">About the Solution</h3>
            <h3 class="results-categoryHeading">Local Exhaust</h3>
        </div>

        <div v-if="submitted" class="results print">
            <h2 class="results-heading">Solutions</h2>

            <div class="results-category">
                <h3 class="results-categoryHeading">Applicable Code</h3>

                <div class="loader" v-if="resultsLoading">
                    <div class="loader-spinner"
                         v-if="resultsLoading && !resultsLoadingError"></div>
                    <div class="loader-error"
                         v-if="resultsLoading && resultsLoadingError">
                        THERE WAS AN ERROR. PLEASE SEARCH AGAIN.
                    </div>
                </div>

                <p v-if="!resultsLoading" class="results-copy">
                    {{ this.results.disclaimers.applicableCodeText }}
                </p>

                <button v-if="
						selectedCheckboxes.length == 0 &&
							!resultsLoading &&
							results.productSolutions.length != 0
					"
                        @click="checkAll"
                        class="results-table-selectAll no-print">
                    Select All
                </button>
                <button v-if="
						selectedCheckboxes.length != 0 &&
							!resultsLoading &&
							results.productSolutions.length != 0
					"
                        @click="checkAll"
                        class="results-table-selectAll no-print">
                    Unselect All
                </button>
                <div v-if="!resultsLoading && submitted"
                     class="results-table-container">
                    <table class="results-table">
                        <tr>
                            <th class="no-print">Print</th>
                            <th class="results-table-rank">
                                Rank
                                <div @click="
										tooltip(
											'The ranking of the solutions is the tool’s best guess at the best-fit solution for the user based on the importance level assigned to First Cost, Energy Use, and Low Noise. All solutions shown below satisfy the design inputs provided above.'
										)
									"
                                     class="results-table-rank-tooltip">
                                    <img src="../assets/info.png" alt="" />
                                </div>
                            </th>
                            <th class="results-table-img">Image</th>
                            <th class="results-table-rank">
                                Model #
                                <div @click="
										tooltip(
											'For Design Flow Rates exceeding 110 cfm, solutions with multiple fans are provided. Advantages of multi-fan solutions include improved distribution and in some cases, quieter fans.'
										)
									"
                                     class="results-table-rank-tooltip">
                                    <img src="../assets/info.png" alt="" />
                                </div>
                            </th>
                            <th>Flow Rate</th>
                            <th>Type</th>
                            <th>Energy Star Rated?</th>
                            <th>Integral Light?</th>
                            <th>Sones</th>
                            <th>Fan Efficacy</th>
                            <th>ASRE; ATRE</th>
                            <th>HERS Index Benefits</th>
                            <th>
                                Annual Ventilation & Infiltration Energy Cost Savings vs. a CFI
                                System
                            </th>
                        </tr>

                        <tr v-for="(result, i) in results.productSolutions" :key="i">
                            <td class="no-print">
                                <input @click="checkBox(result, i, $event)"
                                       class="results-table-checkbox"
                                       type="checkbox" />
                            </td>
                            <td>{{ i + 1 }}</td>
                            <td class="results-table-img">
                                <a :href="result.productUrl" target="_blank">
                                    <img :src="result.imageUrl" :alt="result.modelNumber" />
                                </a>
                            </td>
                            <td>
                                <a :href="result.productUrl" target="_blank" class="results-table-model-number">
                                    {{
									result.modelNumber
                                    }}
                                </a>
                            </td>
                            <td>{{ result.flowRate }}</td>
                            <td>{{ result.type }}</td>
                            <td>{{ result.energyStar ? "Yes" : "No" }}</td>
                            <td>{{ result.integralLight ? "Yes" : "No" }}</td>
                            <td>{{ result.sones }}</td>
                            <td>{{ result.fanEfficacy }}</td>
                            <td>{{ result.asreAtre }}</td>
                            <td>{{ result.hersIndexBenefits }}</td>
                            <td>{{ result.energyCostSavings }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="results-category">
                <h3 class="results-categoryHeading">About the Solution</h3>
                <p class="results-copy">
                    *{{ this.results.disclaimers.flowRateMessage }}
                </p>
                <p class="results-copy">
                    ***Neither the IRC nor the IECC address a maximum allowable sone level for WHMV fans. However, all of Broan's recommended ventilation solutions that do not have a remote-mounted motor have a sone rating of 1.0 sone or less, to provide the homeowner with a quiet system.
                </p>
                <p class="results-copy">
                    ‡ HERS Index benefit is based on building energy simulations of a 4-bedroom, 2400-3600 square foot, new, single-family home with a WHMV flow rate of 75 cfm. The first value represents the expected savings or improvement of the listed solution versus a central-fan integrated (CFI) ventilation system with an electronically commuated motor. The second value represents the expected savings or improvement versus an exhaust-only WHMV system that meets the minimum fan efficacy requirements of the 2012-2018 IECC. Energy prices are based on state average prices as reported by U.S. DOE EIA for 2018.
                </p>
                <p class="results-copy">
                    § Ventilation and infiltration energy cost is calculated using the following equation: (F+V)/ACH*0.3, where F = cost of ventilation fan energy; V = cost of energy used to heat, cool, and dehumidify ventilation and infiltration air to indoor conditions; ACH = annual average infiltration and ventilation air changes per hour; and 0.3 is a multiplier used to normalize the annual average combined infiltration and ventilation rate to 0.3. Assumptions: set point of 72°F in heating and 75°F in cooling, humidistat set point of 60% RH, dehumidifier integrated energy factor of 2.8 liters/kWh, SEER 14 AC, 80 AFUE furnace, 90% duct distribution system efficiency, central air handler with electronically commuated motor efficacy of 2.5 cfm/W, CFI sized to provide 160 cfm when operating if WHMV rate &le; 100 and sized to provide 220 cfm otherwise, building leakage rate compliant with 2012-2018 IECC, and energy prices from 2017 U.S. DOE EIA state averages.
                </p>
            </div>
            <div v-if="!resultsLoading && submitted" class="results-category">
                <h3 class="results-categoryHeading">Local Exhaust</h3>
                <p class="results-copy">
                    {{ localExhaustMsg }}
                </p>
            </div>
        </div>

        <footer v-if="stateSelected" class="specifierForm-footer">
            Broan-NuTone, LLC 262.673.4340
            <a href="https://www.broan-nutone.com">www.broan-nutone.com</a>
        </footer>

        <div v-if="selectedCheckboxes.length != 0" class="specifierForm-stickyBar">
            <div class="specifierForm-stickyBar-inner">
                <h2 class="specifierForm-stickyBar-heading">
                    Email or print your selections above
                </h2>

                <div class="specifierForm-stickyBar-actions">
                    <div class="specifierForm-stickyBar-email">
                        <div>
                            <div>
                                <label class="hideVisually" for="email">Email</label>
                                <input id="specifierForm-stickyBar-email-input"
                                       type="email"
                                       placeholder="Email Address"
                                       @keyup.enter="email()" />
                                <vue-recaptcha class="g-recaptcha" :sitekey="recaptchaKey" size="normal" @verify="validate" />
                            </div>
                            <button :disabled="emailWaitingResponse" class="specifierForm-stickyBar-btn" @click="email()">
                                Email
                            </button>
                        </div>
                        <transition name="fade" mode="out-in">
                            <div v-if="emailErrorMsg" class="emailErrorMsg">
                                Please enter a valid email address
                            </div>
                        </transition>
                        <transition name="fade" mode="out-in">
                            <div v-if="emailSuccessMsg" class="emailSuccessMsg">
                                Email was successfully sent
                            </div>
                        </transition>
                    </div>

                    <button class="specifierForm-stickyBar-btn" @click="print()">
                        Print
                    </button>
                </div>
            </div>
        </div>

        <div @click="closeTooltip"
             class="tooltip-overlay"
             :class="{ 'is-open': tooltipActive }">
            <span class="tooltip-text" v-html="tooltipText"></span>
            <!-- FOR SOLUTIONS PRINTING -->
        </div>
        <div class="print solutions-printed">
            <table>
                <tr>
                    <th>Design Criteria</th>
                    <th>Selection or Result</th>
                </tr>
                <tr>
                    <td>Project Name</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Project Location</td>
                    <td></td>
                </tr>
                <tr>
                    <td>State</td>
                    <td>{{ formData.stateCode }}</td>
                </tr>
                <tr>
                    <td>Conditioned Floor Area (ft<sup>2</sup>)</td>
                    <td>{{ formData.conditionedFloorAreaMax }}</td>
                </tr>
                <tr>
                    <td>Bedrooms</td>
                    <td>{{formData.numberOfBedrooms}}</td>
                </tr>
                <tr>
                    <td>ENERGY STAR Home?</td>
                    <td>{{ formData.isEnergyStarHome }}</td>
                </tr>
                <!-- ***********************************************
                CONDITIONAL CALIFORNIA FIELDS FOR EMAIL/PRINT
            *********************************************** -->
                <tr v-if="formData.stateCode === 'CA'">
                    <td>Account for Infiltration?</td>
                    <td>{{ formData.infiltrationCalculation }}</td>
                </tr>
                <tr v-if="formData.stateCode === 'CA'">
                    <td>Height Above Grade?</td>
                    <td>{{ formData.heightAboveGrade }}</td>
                </tr>
                <tr v-if="formData.stateCode === 'CA'">
                    <td>Conditioned Volume?</td>
                    <td>{{ formData.conditionedVolume }}</td>
                </tr>
                <tr v-if="formData.stateCode === 'CA'">
                    <td>Detached Enevlope Area Volume?</td>
                    <td>{{ formData.detachedEnvelopeRatio }}</td>
                </tr>
                <tr v-if="formData.infiltrationCalculation === 2">
                    <td>Leakage?</td>
                    <td>{{ formData.leakageCfm50 }}</td>
                </tr>
                <!-- ***********************************************
                CONDITIONAL CALIFORNIA FIELDS FOR EMAIL/PRINT
            *********************************************** -->
                <tr>
                    <td>LEED Home?</td>
                    <td>{{ formData.isLeedHome }}</td>
                </tr>
                <tr>
                    <td>System Type?</td>
                    <td>{{ formData.systemType }}</td>
                </tr>
                <tr>
                    <td>Require HEPA filtration?</td>
                    <td>{{ formData.hepaFiltrationRequired }}</td>
                </tr>
                <tr>
                    <td>WHMV Design Flow Rate (cfm)?</td>
                    <td>{{ formData.cfm }}</td>
                </tr>
            </table>
        </div>



    </div>
</template>

<script>
import stateDropdown from "./stateDropdown";
import countyDropdown from "./countyDropdown";
import generalDropdown from "./generalDropdown";
import generalText from "./generalText";
import VueRecaptcha from 'vue-recaptcha';

export default {
	name: "SpecifierForm",
	components: {
		"state-dropdown": stateDropdown,
		"county-dropdown": countyDropdown,
		"general-dropdown": generalDropdown,
		"general-text": generalText,
		"vue-recaptcha": VueRecaptcha
	},
	data() {
		return {
			recaptchaKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
			validateRecaptcha: false,
			reset: 0,
			resetFlow: 0,
			submitted: false,
			stateSelected: false,
			activeState: null,
			resultsLoading: false,
			resultsLoadingError: false,
			states: [],
			counties: [],
			bedrooms: [],
			systemType: [],
			flowRate: [],
			floorArea: [
				"<1,500",
				"1,501-3,000",
				"3,001-4,500",
				"4,501-6,000",
				"6,001-7,500",
				">7,500"
			],
			leed: ["No", "Yes"],
			energy: ["No", "Yes"],
			height: [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40],
			conditioned: '0',
			detached: [0.05,0.1,0.15,0.2,0.25,0.3,0.35,0.4,0.45,0.5,0.55,0.6,0.65,0.7,0.75,0.8,0.85,0.9,0.95,1],
			leakage: '0',
			infiltration: ["Do not account for Infiltration", "Use Default Infiltration", "Use Calculated Infiltration"],
			infiltrationDisplay: {height: false, conditioned: false, detached: false, leakage: false},
			onSiteTestCompleted: ["No", "Yes"],
			requireHEPA: ["No", "Yes"],
			showOnSiteTesting: false,
			showInfiltration: false,
			showHEPA: false,
			formData: {
				stateCode: null,
				county: null,
				conditionedFloorAreaMax: null,
				cfm: null,
				balancedCfm: 0,
				unbalancedCfm: 0,
				systemType: null,
				numberOfBedrooms: null,
				isLeedHome: false,
				isEnergyStarHome: false,
				infiltrationCalculation: 0,
				heightAboveGrade: 8,
				conditionedVolume: 0,
				detachedEnvelopeRatio: 0.05,
				leakageCfm50: 0,
				lowFirstCostImportanceLevel: 5,
				lowEnergyCostImportanceLevel: 5,
				lowNoiseLevelImportanceLevel: 5,
				isOnSiteTestCompleted: false,
				hepaFiltrationRequired: false
			},
			emailFormData: {
				emailAddress: '',
				emailFormFields: {},
				solutions: []
			},
			tooltipText: "",
			tooltipActive: false,
			stateLabel: "State",
			countyLabel: "County",
			bedroomsLabel: "Bedrooms",
			leedLabel: "LEED Home?",
			floorAreaLabel: "Floor Area",
			energyStarLabel: "ENERGY STAR Home?",
			infiltrationLabel: "Account for Infiltration?",
			heightAboveGradeLabel: "Height Above Grade (ft)",
			conditionedVolumeLabel: "Conditioned Volume (ft<sup>3</sup>)",
			detachedEnvelopeAreaLabel: "Detached Envelope Area Ratio",
			leakageLabel: "Leakage (cfm50)",
			systemTypeLabel: "System Type",
			requireHEPALabel: "Require HEPA Filtration?",
			flowRateLabel: "WHMV Design Flow Rate (cfm)",
			onSiteTestCompletedLabel: "On-site testing of WHMV flow rate?",
			updateComponent: false,
			selectedCheckboxes: [],
			emailErrorMsg: false,
			emailSuccessMsg: false,
			emailWaitingResponse: false,
			localExhaustMsg: "",
			updateSystemType: false,
            results: { disclaimers: {}, productSolutions: [] }
		};
	},
	methods: {
        validate(success) {
			this.validateRecaptcha = success
		},
		onSubmit() {
			this.submitted = true;

			this.selectedCheckboxes = [];
			window.scrollTo(
				0,
				document.body.scrollHeight || document.documentElement.scrollHeight
			);

			let params = this.serialize(this.formData);
			this.resultsLoading = true;
			this.resultsLoadingError = false;
			

			this.$api
				.get("/tools/api/v1/Solutions?" + params)
				.then(response => {
					this.results = response;
					
					this.resultsLoading = false;
					this.resultsLoadingError = false;

					if (this.formData.isEnergyStarHome) {
						this.localExhaustMsg =
							"Refer to ENERGY STAR and your local code for local exhaust requirements that are in addition to the WHMV requirements addressed by this tool.";
					} else if (this.formData.isLeedHome) {
						this.localExhaustMsg =
							"Refer to LEED and your local code for local exhaust requirements that are in addition to the WHMV requirements addressed by this tool.";
					} else {
						this.localExhaustMsg =
							"Refer to your local code for local exhaust requirements that are in addition to the WHMV requirements addressed by this tool.";
					}

					window.scrollTo(
						0,
						document.body.scrollHeight || document.documentElement.scrollHeight
					);

				})
				.catch(err => {
					console.log(err);
					this.resultsLoadingError = true;
				});
		},
		getAndPopulateStates() {
			this.$api.get("/tools/api/v1/states")
			.then(response => {
				this.states = response;
			})
			.catch(err => console.log(err));
		},
		getAndPopulateCounties(stateCode) {
			this.$api
				.get("/tools/api/v1/counties?stateCode=" + stateCode)
				.then(response => {
					this.counties = response;
					this.activeState = stateCode;
					this.stateSelected = true;
					this.showHEPA = false;

					if (this.activeState == "MN") {
						this.bedrooms = ["0-1", "2", "3", "4", "5", "6"];
					} 
					else if (this.activeState == "CA") {
						this.bedrooms = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
					}
					else {
						this.bedrooms = ["0-1", "2-3", "4-5", "6-7", ">7"];
					}

					if (this.activeState == "VT") {
						if (this.formData.isLeedHome || this.formData.isEnergyStarHome) {
							this.showOnSiteTesting = false;
							this.formData.isOnSiteTestCompleted = true;
						} else {
							this.showOnSiteTesting = true;
							this.formData.isOnSiteTestCompleted = false;
						}
					} else {
						this.showOnSiteTesting = false;
						this.formData.isOnSiteTestCompleted = false;
					}

					if (this.activeState == "CA") {
						this.showInfiltration = true
					}
					else {
						this.showInfiltration = false
					}

					if (this.activeState == "MN" || this.activeState == "WI") {
						this.systemType = ["Balanced"];
						this.showHEPA = true;
						this.selectedSystemType("Balanced");
					} else {
						this.systemType = [
							"Any",
							"Balanced",
							"Supply",
							"Exhaust - Multiple",
							"Exhaust - Single"
						];
						this.selectedSystemType("Any");
					}

					if (response.length == 0) {
						this.formData.county = '';
					} else {
						this.formData.county = response[0].countyName;
					}
					this.formData.stateCode = stateCode;

					//Resetting Form when State changes
					this.formData.numberOfBedrooms = 1;
					this.formData.isLeedHome = false;
					this.formData.isEnergyStarHome = false;
					this.formData.infiltrationCalculation = 0;
					this.formData.heightAboveGrade = 8;
					this.formData.conditionedVolume = 0;
					this.formData.detachedEnvelopeRatio = 0.05;
					this.formData.leakageCfm50 = 0;
					this.formData.conditionedFloorAreaMax = 1500;
					this.reset++;
					this.updateSystemType = !this.updateSystemType
					this.getAndPopulateCfm()
					
				})
				.catch(err => {
					console.log("Error getting counties:", err);
				});
		},
		getAndPopulateCfm() {
			let data = Object.entries(this.formData).reduce((_data, def) => {
				if (
					def[0] == "stateCode" ||
					def[0] == "county" ||
					def[0] == "conditionedFloorAreaMax" ||
					def[0] == "isOnSiteTestCompleted" ||
					def[0] == "numberOfBedrooms" ||
					def[0] == "isEnergyStarHome"||
					def[0] == "infiltrationCalculation" ||
					def[0] == "heightAboveGrade" ||
					def[0] == "conditionedVolume" ||
					def[0] == "detachedEnvelopeRatio" ||
					def[0] == "leakageCfm50") {
						_data.push(def)
					}
					return _data
			}, [])

			let serialized = data.reduce((_data, item) => {
				_data += item[0] + '=' + item[1] + '&'
				return _data
			}, '');

			serialized = serialized.substring(0, serialized.length - 1)
			
			this.$api
				.get("/tools/api/v1/CfmOptions?" + serialized)
				.then(response => {
					this.flowRate = response;
					let flowRate = response[0];
					if (flowRate.includes(";")) {
						flowRate = flowRate.includes("; ") ? flowRate.split("; ") : flowRate.split(";");
						this.formData.balancedCfm = parseInt(flowRate[0]);
						this.formData.unbalancedCfm = parseInt(flowRate[1]);
						this.formData.cfm = 0;
					}
					else {
						this.formData.balancedCfm = 0;
						this.formData.unbalancedCfm = 0;
						this.formData.cfm = parseInt(flowRate);
					}
					this.resetFlow++;
				})
				.catch(err => {
					console.log(err);
				});

		},
		selectedState(state) {
			this.getAndPopulateCounties(state);
		},
		selectedCounty(county) {
			this.formData.county = county;
			this.getAndPopulateCfm();
		},
		selectedBedrooms(bedrooms) {
			if (bedrooms > 3 && this.activeState == "VT") {
				this.systemType = ["Any", "Balanced", "Supply", "Exhaust - Multiple"];
			}
			this.formData.numberOfBedrooms = bedrooms;
			this.getAndPopulateCfm();
		},
		selectedLeed(leed) {
			if (this.activeState == "VT") {
				if (leed || this.formData.isEnergyStarHome) {
					this.showOnSiteTesting = false;
					this.formData.isOnSiteTestCompleted = true;
				} else {
					this.showOnSiteTesting = true;
					this.formData.isOnSiteTestCompleted = false;
				}
			}

			this.formData.isLeedHome = leed;
			this.getAndPopulateCfm();
		},
		selectedEnergyStar(energyStar) {
			if (this.activeState == "VT") {
				if (energyStar || this.formData.isLeedHome) {
					this.showOnSiteTesting = false;
					this.selectedOnSiteTest(true);
				} else {
					this.showOnSiteTesting = true;
					this.selectedOnSiteTest(false);
				}
			}

			this.formData.isEnergyStarHome = energyStar;
			this.getAndPopulateCfm();
		},
		selectedInfiltration(infiltration) {
			switch (infiltration) {
				case 0:
					this.infiltrationDisplay = {height: false, conditioned: false, detached: false, leakage: false};
					break;
				case 1:
					this.infiltrationDisplay = {height: true, conditioned: true, detached: true, leakage: false};
					break;
				case 2:
					this.infiltrationDisplay = {height: true, conditioned: true, detached: true, leakage: true};
					break;
				default:
					break;
			}
			this.formData.infiltrationCalculation = infiltration
			this.getAndPopulateCfm();
		},
		selectedHeightAboveGrade(heightAboveGrade) {
			this.formData.heightAboveGrade = heightAboveGrade;
			this.getAndPopulateCfm();
		},
		selectedConditionedVolume(conditionedVolume) {
			this.formData.conditionedVolume = parseInt(conditionedVolume);
			this.getAndPopulateCfm();
		},
		selectedDetachedEnvelopeArea(detachedEnvelopeArea) {
			this.formData.detachedEnvelopeRatio = detachedEnvelopeArea;
			this.getAndPopulateCfm();
		},
		selectedLeakage(leakage) {
			this.formData.leakageCfm50 = parseInt(leakage);
			this.getAndPopulateCfm();
		},
		selectedFloorArea(floorArea) {
			this.formData.conditionedFloorAreaMax = floorArea;
			this.getAndPopulateCfm();
		},
		selectedSystemType(systemType) {
			this.formData.systemType = systemType
			if (systemType == "Balanced") {
				this.showHEPA = true;
				this.formData.hepaFiltrationRequired = false;
			} else {
				this.showHEPA = false;
				this.formData.hepaFiltrationRequired = false;
			}
			this.formData.systemType = systemType;
		},
		selectedFlowRate(flowRate) {
			if (flowRate.includes(";")) {
				flowRate = flowRate.includes("; ") ? flowRate.split("; ") : flowRate.split(";");
				this.formData.balancedCfm = parseInt(flowRate[0]);
				this.formData.unbalancedCfm = parseInt(flowRate[1]);
				this.formData.cfm = 0;
			}
			else {
				this.formData.balancedCfm = 0;
				this.formData.unbalancedCfm = 0;
				this.formData.cfm = parseInt(flowRate);
			}
		},
		selectedRequireHEPA(HEPA) {
			this.formData.hepaFiltrationRequired = HEPA;
		},
		selectedOnSiteTest(test) {
			this.formData.isOnSiteTestCompleted = test;
			this.getAndPopulateCfm();
		},
		selectedFirstCost(e) {
			this.applyFill(e);
			this.formData.lowFirstCostImportanceLevel = parseInt(e.target.value);
		},
		selectedEnergyCost(e) {
			this.applyFill(e);
			this.formData.lowEnergyCostImportanceLevel = parseInt(e.target.value);
		},
		selectedSones(e) {
			this.applyFill(e);
			this.formData.lowNoiseLevelImportanceLevel = parseInt(e.target.value);
		},
		applyFill(slider) {
			const percentage =
				(100 * (slider.target.value - slider.target.min)) /
				(slider.target.max - slider.target.min);
			const bg =
				"linear-gradient(90deg, #002855 " +
				percentage +
				"%" +
				", #e0e0e0 " +
				(percentage + 0.1) +
				"%)";
			slider.srcElement.style.background = bg;
		},
		checkAll() {
			let checkboxes = [
				...document.querySelectorAll(".results-table-checkbox")
			];
			if (this.selectedCheckboxes.length == 0) {
				this.selectedCheckboxes = [];
				checkboxes.forEach((box, i) => {
					box.checked = true;
					box.closest('tr').classList.add('print-row')
					this.selectedCheckboxes.push(i);
				});
			} else {
				checkboxes.forEach(box => {
					box.checked = false
					box.closest('tr').classList.remove('print-row')
				});
				this.selectedCheckboxes = [];
			}
		},
		checkBox(row, i, e) {

			if(e.target.closest('tr').classList.contains('print-row')) {
				e.target.closest('tr').classList.remove('print-row')
			} else {
				e.target.closest('tr').classList.add('print-row')
			}

			if (this.selectedCheckboxes.includes(i)) {
				let index = this.selectedCheckboxes.indexOf(i);
				this.selectedCheckboxes.splice(index, 1);
			} else {
				this.selectedCheckboxes.push(i);
			}
		},
		print() {
			window.print();
		},
		email() {
			let val = this.$el.querySelector("#specifierForm-stickyBar-email-input")
				.value;
			let emailValid = this.validateEmail(val);

			if (emailValid) {
				this.emailErrorMsg = false;
			this.emailFormData.emailAddress = val
			this.emailFormData.recaptchaToken = "" + this.validateRecaptcha
			this.emailFormData.emailFormFields = this.formData
			this.emailFormData.solutions = []

			this.selectedCheckboxes.forEach(box => {
				this.emailFormData.solutions.push(this.results.productSolutions[box])
			})
			this.emailWaitingResponse = true
			this.$api
				.post("/tools/api/v1/EmailSolutions", this.emailFormData)
				.then(() => {
					this.emailSuccessMsg = true
					this.emailWaitingResponse = false
					this.$el.querySelector('#specifierForm-stickyBar-email-input').value = ''
					
					setTimeout(() => {
						this.emailSuccessMsg = false
					}, 3000)
				})
				.catch(err => {
					this.emailWaitingResponse = false
					console.log('email post error', err)
				})

			} else {
				this.emailErrorMsg = true;
			}	

		},
		validateEmail(email) {
			// eslint-disable-next-line
			let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return regex.test(String(email).toLowerCase());
		},
		serialize(formData) {
			let serialized = [];

			Object.keys(formData).forEach(key => {
				serialized.push(key + "=" + encodeURIComponent(formData[key]));
			});
			return serialized.join("&");
		},
		tooltip(text) {
			this.tooltipActive = true;
			this.tooltipText = text;
			document.body.style.overflow = "hidden";
		},
		closeTooltip() {
			this.tooltipActive = false;
			this.tooltipText = "";
			document.body.style.overflow = "visible";
		},
		getBedroomsValue(str) {
			switch (str) {
				case "0-1":
					return 1;
				case "2-3":
					return 3;
				case "4-5":
					return 5;
				case "6-7":
					return 7;
				case ">7":
					return 99;
				case "2":
					return 2;
				case "3":
					return 3;
				case "4":
					return 4;
				case "5":
					return 5;
				case "6":
					return 6;
				default:
					break;
			}
		}
	},
	updated() {
		let sliders = [...this.$el.querySelectorAll(".range-sliderInput")];
		if (sliders) {
			sliders.forEach(slider => {
				const percentage =
					(100 * (slider.value - slider.min)) / (slider.max - slider.min);
				const bg =
					"linear-gradient(90deg, #002855 " +
					percentage +
					"%" +
					", #e0e0e0 " +
					(percentage + 0.1) +
					"%)";
				slider.style.background = bg;
			});
		}
	},
	mounted() {
		this.submitted = false;
	},
	created() {
		this.getAndPopulateStates();
	}
};                                                    
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.specifierForm {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding: 1rem;
	width: 100%;
	height: 100vh;
	position: relative;
}
.specifierForm-logo {
	display: flex;
	justify-content: center;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 1rem;
}
.specifierForm-logo img {
	width: 160px;
}
.specifierForm-heading {
	font-size: 1.5rem;
	text-align: center;
	margin-bottom: 0.5rem;
}
.specifierForm-subHeading {
	font-size: 1.25rem;
	text-align: center;
	font-weight: 400;
	margin-bottom: 2rem;

	@media (max-width: 550px) {
		font-size: 1rem;
		margin-bottom: 1rem;
	}
}
.specifierForm-instruction {
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 2.25rem;
	color: #002855;

	&:before,
	&:after {
		content: "";
		height: 7px;
		width: 7px;
		min-height: 7px;
		min-width: 7px;
		border-radius: 100px;
		background-color: #e6391e;
	}
	&:before {
		margin-right: 10px;
	}
	&:after {
		margin-left: 10px;
	}

	@media (max-width: 550px) {
		font-size: 14px;
		padding: 0 10px;
		text-align: center;
	}
}
.specifierForm-footer {
	font-size: 14px;
	margin-bottom: 2rem;
	a {
		color: #000;
		font-weight: 600;

		&:hover {
			color: #002855;
			text-decoration: none;
		}
	}
}

.specifierForm-submit {
	margin: 0 0 1rem 0;
	width: 100%;
	text-align: center;
}
.specifierForm-submitBtn {
	border: none;
	background-color: #002855;
	border-radius: 5px;
	padding: 15px 30px;
	width: 150px;
	color: #fff;
	font-size: 18px;
	cursor: pointer;
	transition: all 0.3s;
	text-transform: uppercase;
	font-weight: 500;

	&:hover {
		background-color: #013b7e;
	}
}
.specifierForm-stickyBar {
	position: sticky;
	position: fixed; // for IE11
	width: 100%;
	bottom: 0;
	background-color: rgb(27, 27, 27);
	left: 50%;
	transform: translateX(-50%);
	padding: 2rem 1rem;

	&-inner {
		max-width: 1200px;
		margin: 0 auto;
	}
	&-heading {
		color: #ffffff;
		font-size: 1.25rem;
		text-align: center;
		margin-bottom: 1rem;

		@media (max-width: 550px) {
			font-size: 1rem;
		}
	}
	&-actions {
		padding: 1rem 0;
		text-align: center;
		display: flex;
		width: 100%;
		justify-content: center;

		@media (max-width: 550px) {
			display: block;
		}
	}

	&-email {
		color: #013b7e;
		margin-bottom: 0rem;
		margin-right: 1rem;

		> div {
			display: flex;
			flex-wrap: nowrap;
		}

		@media (max-width: 550px) {
			margin-right: 0;
			margin-bottom: 1rem;
		}
	}

	&-btn {
		border: 2px solid #ffffff;
		border-radius: 5px;
		color: #ffffff;
		padding: 5px 10px;
		background-color: #013b7e;
		font-size: 14px;
		font-weight: bold;
		height: 40px;
		cursor: pointer;
		width: 70px;
		&:hover {
			background-color: #002855;
		}
		&:disabled {
			background-color: #353535;
			cursor: not-allowed;
			pointer-events: none;
		}
	}
}
#specifierForm-stickyBar-email-input {
	border: none;
	margin-right: 10px;
	margin-bottom: 10px;
	padding: 10px;
	font-size: 14px;
	border-radius: 5px;
	width: 300px;
}
.emailErrorMsg {
	background-color: red;
	border-radius: 5px;
	padding: 5px 10px;
	color: #ffffff;
	text-align: left;
	margin-top: 10px;
	width: 300px;
	font-size: 16px;
	font-weight: bold;
}
.emailSuccessMsg {
	background-color: rgb(34, 182, 34);
	border-radius: 5px;
	padding: 5px 10px;
	color: #ffffff;
	text-align: left;
	margin-top: 10px;
	width: 300px;
	font-size: 16px;
	font-weight: bold;
}

// Form Block Styling
.formBlocks {
	display: flex;
	flex-wrap: wrap;
}
.formBlock {
	display: flex;
	margin-bottom: 1.5rem;
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: calc(50% - 1rem);
	margin-right: 1rem;

	&--highlight {
		padding: 2rem;
		border: 3px solid #006;
		box-shadow: 0 7px 10px rgba(0, 0, 0, 0.2);
		flex-basis: 100%;
		margin-right: 0;
		border-radius: 5px;

		@media (max-width: 550px) {
			padding: 1rem;
		}
	}
	&--full {
		flex-basis: 100%;

		.dropdown {
			width: 50%;

			@media (max-width: 550px) {
				width: 100%;
				flex-basis: 100%;
			}
		}
	}
	&--rangeGrid {
		@media (max-width: 1024px) {
			width: 100%;
			flex-basis: 100%;
		}
	}

	@media (max-width: 550px) {
		margin-bottom: 2rem;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 100%;
		width: 100%;
		margin-right: 0;
	}
}

// Tooltip Overlay Styling
.tooltip-overlay {
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.5);
	height: 100vh;
	width: 100vw;
	left: 50%;
	transform: translateX(-50%);
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	pointer-events: none;
	z-index: -1;
}
.tooltip-text {
	position: absolute;
	left: 50%;
	top: 10%;
	padding: 1rem;
	transform: translateX(-50%);
	background-color: #ffffff;
	font-size: 18px;
	font-weight: 500;
	color: #063992;
	max-width: 1200px;
	width: 90%;
	text-align: center;
	filter: blur(0);
}
.tooltip-overlay.is-open {
	opacity: 1;
	pointer-events: all;
	z-index: 1000;
}

// Range Slider Styling
.range-grid {
	border: 1px solid #a2a4b2;
	border-radius: 5px;
	width: 100%;
}
.range-headings {
	border-bottom: 1px solid #a2a4b2;
	display: flex;
	@media (max-width: 550px) {
		font-size: 0.75rem;
	}
}
.range-heading:first-child {
	flex: 1 1 25%;
	width: 25%;
	padding: 10px;
	font-weight: 700;
	border-right: 1px solid #a2a4b2;
}
.range-heading:last-child {
	display: inline-block;
	flex: 1 1 75%;
	width: 75%;
	display: flex;
	justify-content: space-between;
	padding: 10px;
	font-weight: 700;
}
.range-slider {
	width: 100%;
	display: flex;
	justify-content: center;
	&:last-child {
		.range-sliderLabel {
			border-bottom: none;
		}
		.range-sliderContainer {
			border-bottom: none;
		}
	}
	.range-sliderLabel {
		display: inline-flex;
		flex: 1 1 25%;
		width: 25%;
		border-right: 1px solid #a2a4b2;
		border-bottom: 1px solid #a2a4b2;
		padding: 10px;
		align-items: center;
		font-weight: 500;
		@media (max-width: 550px) {
			font-size: 0.75rem;
		}
	}
	.range-sliderContainer {
		flex: 1 1 75%;
		width: 75%;
		border-bottom: 1px solid #a2a4b2;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 20px;
		height: 60px;
	}
	.range-sliderInput {
		width: 100%;
	}
}

.range-sliderContainer {
	width: 100%;
	margin: 0;
}

.range-sliderInput {
	-webkit-appearance: none;
	width: calc(100% - (73px));
	height: 10px;
	border-radius: 5px;
	background: #d7dcdf;
	outline: none;
	padding: 0;
	margin: 0;
}
.range-sliderInput::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #2c3e50;
	cursor: pointer;
	transition: background 0.15s ease-in-out;
}
.range-sliderInput::-webkit-slider-thumb:hover {
	background: #002855;
}
.range-sliderInput:active::-webkit-slider-thumb {
	background: #002855;
}
.range-sliderInput::-moz-range-thumb {
	width: 20px;
	height: 20px;
	border: 0;
	border-radius: 50%;
	background: #2c3e50;
	cursor: pointer;
	transition: background 0.15s ease-in-out;
}
.range-sliderInput::-moz-range-thumb:hover {
	background: #002855;
}
.range-sliderInput:active::-moz-range-thumb {
	background: #002855;
}
.range-sliderInput:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 2px #fff, 0 0 0 6px #002855;
}

::-moz-range-track {
	background: #d7dcdf;
	border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
	border: 0;
}

// Results Styling
.results-empty {
	* {
		margin-bottom: 1rem;
	}
}
.results {
	&-heading {
		text-transform: uppercase;
		margin-bottom: 1rem;
		font-size: 1.75rem;
	}
	&-table-container {
		overflow-x: scroll;
		max-width: 100%;
		width: 100%;
	}
	&-categoryHeading {
		text-transform: uppercase;
		background-color: #006;
		color: #fff;
		padding: 10px;
		font-size: 1rem;
	}
	&-copy {
		margin: 1rem 0;
		font-size: 14px;
	}

	&-table {
		table-layout: fixed;
		overflow-x: auto;
		font-size: 14px;
		border-collapse: collapse;
		margin-bottom: 1rem;
		// width: 100%;

		th,
		td {
			border: 1px solid #006;
			padding: 5px;
			text-align: center;
		}

		th.results-table-img {
			min-width: 140px;
		}
		td.results-table-img {
			a {
				display: inline-block;
				max-height: 140px;
				max-width: 140px;
				height: 140px;
				width: 140px;

				img {
					height: 100%;
					max-width: 100%;
				}
			}
		}

		tr th:not(.results-table-img),
		tr td:not(.results-table-img) {
			max-width: 100px;
		}

		&-rank {
			text-align: center;
			position: relative;

			&-tooltip {
				width: 20px;
				margin: 10px auto 0;
				cursor: pointer;
				img {
					max-width: 100%;
					width: 100%;
				}
			}
		}
	}

	&-table-model-number {
		font-weight: bold;
		color: #000;

		&:hover {
			text-decoration: none;
			color: #006;
		}
	}

	&-table-selectAll {
		border: none;
		background-color: #002855;
		padding: 10px 15px;
		color: #fff;
		margin-bottom: 1rem;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: bold;
		border-radius: 5px;
		cursor: pointer;

		&:hover {
			background-color: #013b7e;
		}
	}
}

// Loader styling
.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 175px;
	width: 100%;
	background: #ffffff;
	margin-top: 1rem;
	&-spinner {
		position: relative;
		display: block;
		width: 120px;
		height: 120px;
		margin: 0 auto;
		&:after {
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100px;
			height: 100px;
			margin: 8px;
			border-radius: 50%;
			border: 6px solid #013b7e;
			border-color: #013b7e transparent #bf3119 transparent;
			animation: spin 1.75s linear infinite forwards;
		}
	}
	&-error {
		background-color: #ff0000;
		color: #ffffff;
		padding: 1rem;
		font-weight: bold;
		border-radius: 5px;
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg) scale(1);
		}
		50% {
			transform: rotate(180deg) scale(1.25);
		}
		100% {
			transform: rotate(360deg) scale(1);
		}
	}
}

.fade-enter {
	opacity: 0;
}
.fade-enter-active {
	transition: opacity 0.5s ease-out;
}
.fade-leave-to {
	opacity: 0;
}

.solutions-printed {
	display: none;

	table {
		table-layout: fixed;
		overflow-x: auto;
		font-size: 14px;
		border-collapse: collapse;
		width: 100%;
		tr  {
			th,td {
				padding: 10px;
			border: 1px solid #006;
			width: 50%;
			}
		}
	}
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media print {
	body * {
		visibility: hidden;
	}
	.specifierForm-form {
		display: none;
	}
	.solutions-printed {
		display: block;
	}

	.print {
		visibility: visible;

		* {
			visibility: visible;
		}
	
	}
	.no-print {
		display: none;
	}
	.results-table * {
		font-size: 12px;
	}
	.results-table {
		tr:not(.print-row) {
			display: none;
		}
		tr:first-child {
			display: table-row;
		}
	}
	.results-categoryHeading {
		background-color: #000000;
		color: #ffffff;
	}
	th.results-table-img {
			min-width: 50px;
		}
		td.results-table-img {
			a {
				display: inline-block;
				max-height: 100px;
				max-width: 100px;
				height: 100px;
				width: 100px;
			}
		}
}
</style>
