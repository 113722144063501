import axios from 'axios'

export default class {
  constructor() {
      this.api = axios.create({
          baseURL: process.env.VUE_APP_BASEPATH,
          // baseURL: 'https://bnt-specifier-tools-2019.azurewebsites.net/',
          timeout: 300000,
          headers: {'X-Requested-With': 'XMLHttpRequest'}
      })
  }

  get(url) {
      return this.api.get(url)
          .then(res => {
              if (res)
                  return res['data'] ? res.data : {}
          })
  }

  delete(url, query) {
      return this.api.delete(url, { params: query })
          .then(res => {
              if (res)
                  return res['data'] ? res.data : {}
          })
  }

  post(url, data) {
      return this.api.post(url, data)
          .then(res => {
              if (res)
                  return res['data'] ? res.data : {}
          })
  }

  put(url, data) {
      return this.api.put(url, data)
          .then(res => {
              if (res)
                  return res['data'] ? res.data : {}
          })
  }
}