<template>
	<main id="broanWHMV">
		<SpecifierForm />
	</main>
</template>

<script>
import SpecifierForm from "./components/SpecifierForm.vue";

export default {
	name: "broanWHMV",
	components: {
		SpecifierForm
	}
};
</script>

<style lang="scss">
html,
body {
	margin: 0;
	padding: 0;
	font-size: 16px;
}
*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
#broanWHMV {
	font-family: "Avenir", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}

.hideVisually {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	clip-path: inset(50%);
	border: 0;
}
</style>
