<template>
	<ul class="input">
		<li :class="{ 'input-label--tooltip': toolText }" class="input-label">
			<span v-html="label"></span>
			<div v-if="toolText" @click="tooltip(toolText)" class="input-tooltip">
				<img src="../assets/info.png" alt="" />
			</div>
		</li>
		<li class="input-inputContainer">
			<input class="input-input" type="number" :value="value" @input="setValue($event, $event.target.value)">
		</li>
	</ul>
</template>

<script>
export default {
	name: "generalText",
	props: ["activeState", "options", "label", "toolText"],
	data() {
		return {
			tooltipText: "",
			tooltipActive: false,
			value: '',
			results: []
		};
	},
	methods: {
		tooltip(text) {
			this.$emit("tooltip", text);
			this.tooltipActive = true;
			this.tooltipText = text;
		},
		setValue(event, value) {
			this.value = value;
			switch (this.label) {
				case "Conditioned Volume (ft<sup>3</sup>)":
					this.$emit("selectedConditionedVolume", value);
					break;
				case "Leakage (cfm50)":
					this.$emit("selectedLeakage", value);
					break;
				default:
					break;
			}
		},
	},
	mounted() {
		this.value = this.options
	}
};
</script>
<style lang="scss">
// GLOBAL input STYLING
.input {
	list-style: none;
	position: relative;
	border: 1px solid #a2a4b2;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	width: 100%;
	height: 55px;
	cursor: pointer;
	padding: 0;
	border-radius: 5px;
}
.input-label {
	position: absolute;
	top: -13px;
	left: 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background: #fff;
	padding: 0 10px 0 10px;
	font-weight: 600;
	font-size: 1.25em;
	font-family: Lato, sans-serif;
	color: #002855;

	@media (max-width: 870px) {
		font-size: 1rem;
	}
}
.input-label--tooltip {
	padding: 0 40px 0 10px;
}
.input-inputContainer {
	position: relative;
	height: 100%;
	width: 100%;
}
.input-input {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	background: none;
	border: none;
	appearance: none;
	outline: none;
	font-family: Lato, sans-serif;
	color: #444444;
	padding: 20px 0px 14px 0;
	padding-left: 25px;
	list-style-position: inside;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: bold;
	font-size: 16px;
}

.input-tooltip {
	width: 20px;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
}
.input-tooltip img {
	max-width: 100%;
	width: 100%;
}
</style>
