<template>
	<ul class="dropdown">
		<li 
			:class="{ 'dropdown-label--tooltip': toolText }"
			class="dropdown-label"
		>
			{{ label }}
			<div v-if="toolText" @click="tooltip(toolText)" class="dropdown-tooltip">
				<img src="../assets/info.png" alt="" />
			</div>
		</li>
		<li
			v-if="options.length != 0"
			@click="toggleListVisibility"
			id="dropdown-selected"
			tabindex="0"
		>
			{{ options[0].countyName }}
		</li>
		<li v-else id="dropdown-selected" tabindex="0">N/A</li>
		<div class="dropdown-arrow">
			<svg
				width="15"
				height="10"
				viewBox="0 0 15 10"
				fill-rule="evenodd"
			>
				<title>Open drop down</title>
				<path d="M10 0L5 5 0 0z"></path>
			</svg>
		</div>
		<li aria-expanded="false" role="list" class="dropdown-listContainer">
			<ul v-if="options.length != 0" class="dropdown-list">
				<li
					v-for="(option, i) in options"
					:key="i"
					@click="selectItem($event, option)"
					class="dropdown-listItem"
				>
					{{ option.countyName }}
				</li>
			</ul>
		</li>
	</ul>
</template>

<script>
export default {
	name: "countyDropdown",
	props: ["activeState", "options", "label", "toolText"],
	data() {
		return {
			tooltipText: "",
			tooltipActive: false,
			results: []
		};
	},
	methods: {
		setSelectedListItem(e) {
			let dropdownSelectedNode = this.$el.querySelector("#dropdown-selected");
			let selectedTextToAppend = document.createTextNode(e.target.innerText);
			dropdownSelectedNode.innerHTML = '';
			dropdownSelectedNode.appendChild(selectedTextToAppend);
		},
		closeList() {
			let list = this.$el.querySelector(".dropdown-list");
			let listContainer = this.$el.querySelector(".dropdown-listContainer");
			let dropdownArrow = this.$el.querySelector(".dropdown-arrow");

			if (list) {
				list.classList.remove("is-open");
				dropdownArrow.classList.remove("is-open");
				listContainer.setAttribute("aria-expanded", false);
			}
		},
		toggleListVisibility() {
			let list = this.$el.querySelector(".dropdown-list");
			let listContainer = this.$el.querySelector(".dropdown-listContainer");
			let dropdownArrow = this.$el.querySelector(".dropdown-arrow");
			list.classList.toggle("is-open");
			dropdownArrow.classList.toggle("is-open");
			listContainer.setAttribute(
				"aria-expanded",
				list.classList.contains("is-open")
			);
		},
		tooltip(text) {
			this.$emit("tooltip", text);
			this.tooltipActive = true;
			this.tooltipText = text;
		},
		selectItem(event, option) {
			this.setSelectedListItem(event);
			this.closeList();
			this.$emit("selectedCounty", option.countyName);
		}
	},
	updated() {
		// this.$emit("selectedCounty", this.options[0].countyName);
		console.log('updated county', this.options)
	},
	mounted() {
		
		document.addEventListener("click", e => {
			if (!this.$el.contains(e.target)) {
				this.closeList();
			}
		});

		document.addEventListener("touchstart", e => {
			if (!this.$el.contains(e.target)) {
				this.closeList();
			}
		});
	}
};
</script>
